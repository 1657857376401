import getQueryStringValue from 'core/libs/helpers/getQueryStringValue';
import {navigateTo} from 'gatsby-link';
import React from 'react';
import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';

const DiscoursePage = () => <h2>Redirecting...</h2>;

const mapStateToProps = ({auth}) => ({
  authenticated: auth.authenticated,
  data: auth,
});

const enhance = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      if (this.props.authenticated) {
        const token = this.props.data.key;
        const sso = getQueryStringValue('sso');
        const sig = getQueryStringValue('sig');
        window.location.href = `${process.env.GATSBY_API}profile/users/discourse_sso/?apikey=${token}&sig=${sig}&sso=${sso}`;
      } else {
        navigateTo('/auth/login');
      }
    },
  }),
);

export default enhance(DiscoursePage);
